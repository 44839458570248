// theme.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkThemeEnabled = new BehaviorSubject<boolean>(false);
  isDarkTheme = this.darkThemeEnabled.asObservable();

  enableDarkTheme(enable: boolean) {
    this.darkThemeEnabled.next(enable);
  }
}
